import { createApi } from '@reduxjs/toolkit/query/react';
import { Loan, LoanRequestConfig, RequestLoanInput } from 'core';

import config from '@config';

import axiosBaseQuery from '../axiosBaseQuery';

enum LoanEnum {
  ReducerPath = 'myLoansApi',
  TagType = 'attendances',
}

const myLoansApi = createApi({
  reducerPath: LoanEnum.ReducerPath,
  baseQuery: axiosBaseQuery({
    baseUrl: config.apiBaseUrl,
  }),
  keepUnusedDataFor: 30,
  tagTypes: [LoanEnum.TagType],
  endpoints: build => ({
    getMyLoans: build.query<Loan[], {}>({
      query: args => ({
        url: `/my-loans`,
        method: 'GET',
      }),
      transformResponse: (response: Loan[]) => response,
      providesTags: [LoanEnum.TagType],
    }),
    getLoanRequestConfig: build.query<LoanRequestConfig, {}>({
      query: args => ({
        url: `/loan-request-config`,
        method: 'GET',
      }),
      transformResponse: (response: LoanRequestConfig) => response,
      providesTags: [LoanEnum.TagType],
    }),
    requestLoan: build.mutation<RequestLoanInput, Partial<RequestLoanInput>>({
      query: data => ({
        url: '/request-loan',
        method: 'POST',
        data,
      }),
      invalidatesTags: [LoanEnum.TagType],
    }),
    updateLoan: build.mutation<RequestLoanInput, Partial<RequestLoanInput>>({
      query: data => ({
        url: '/loan',
        method: 'PUT',
        data,
      }),
      invalidatesTags: [LoanEnum.TagType],
    }),
    deleteLoan: build.mutation<{ id: number }, number>({
      query: id => ({
        url: '/loan',
        method: 'DELETE',
        params: { id },
      }),
      invalidatesTags: [LoanEnum.TagType],
    }),
  }),
});

export const {
  useLazyGetMyLoansQuery,
  useLazyGetLoanRequestConfigQuery,
  useRequestLoanMutation,
  useDeleteLoanMutation,
  useUpdateLoanMutation,
} = myLoansApi;

export default myLoansApi;
