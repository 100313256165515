import { createApi } from '@reduxjs/toolkit/query/react';
import { AttendanceCreateInput } from 'core';

import config from '@config';
import {
  Attendance,
  AttendanceSummaries,
  EmployeeAttendance,
  EmployeeOvertime,
} from '@types';

import axiosBaseQuery from './axiosBaseQuery';

enum AttendancesEnum {
  ReducerPath = 'attendancesApi',
  TagType = 'attendances',
}

const attendancesApi = createApi({
  reducerPath: AttendancesEnum.ReducerPath,
  baseQuery: axiosBaseQuery({
    baseUrl: config.apiBaseUrl,
  }),
  keepUnusedDataFor: 30,
  tagTypes: [AttendancesEnum.TagType],
  endpoints: build => ({
    getAttendances: build.query<
      EmployeeAttendance[],
      { start?: string; end?: string }
    >({
      query: args => ({
        url: `/employee-attendances`,
        method: 'GET',
        params: { start: args.start, end: args.end },
      }),
      transformResponse: (response: EmployeeAttendance[]) => response,
      providesTags: [AttendancesEnum.TagType],
    }),
    getMyAttendances: build.query<
      Attendance[],
      { start?: string; end?: string }
    >({
      query: args => ({
        url: `/my-attendances`,
        method: 'GET',
        params: { start: args.start, end: args.end },
      }),
      transformResponse: (response: Attendance[]) => response,
      providesTags: [AttendancesEnum.TagType],
    }),
    createEmployeeAttendance: build.mutation<
      AttendanceCreateInput,
      Partial<AttendanceCreateInput>
    >({
      query: data => ({
        url: '/employee-attendances',
        method: 'POST',
        data,
      }),
      invalidatesTags: [AttendancesEnum.TagType],
    }),
    deleteEmployeeAttendance: build.mutation<{ id: number }, number>({
      query: id => ({
        url: '/employee-attendances',
        method: 'DELETE',
        params: { id },
      }),
      invalidatesTags: [AttendancesEnum.TagType],
    }),
    getEmployeeOvertimes: build.query<
      EmployeeOvertime[],
      { start?: string; end?: string }
    >({
      query: args => ({
        url: `/employee-overtimes`,
        method: 'GET',
        params: { start: args.start, end: args.end },
      }),
      transformResponse: (response: EmployeeOvertime[]) => response,
      providesTags: [AttendancesEnum.TagType],
    }),
    getLeaveSummary: build.query<AttendanceSummaries[], { year?: number }>({
      query: args => ({
        url: `/leave-summaries`,
        method: 'GET',
        params: { year: args.year },
      }),
      transformResponse: (response: AttendanceSummaries[]) => response,
      providesTags: [AttendancesEnum.TagType],
    }),
    updateEmployeeAttendance: build.mutation<
      AttendanceCreateInput,
      Partial<AttendanceCreateInput>
    >({
      query: data => ({
        url: '/employee-attendances',
        method: 'PUT',
        data,
      }),
      invalidatesTags: [AttendancesEnum.TagType],
    }),
  }),
});

export const {
  useCreateEmployeeAttendanceMutation,
  useDeleteEmployeeAttendanceMutation,
  useLazyGetMyAttendancesQuery,
  useLazyGetAttendancesQuery,
  useGetEmployeeOvertimesQuery,
  useLazyGetLeaveSummaryQuery,
  useUpdateEmployeeAttendanceMutation,
} = attendancesApi;
export default attendancesApi;
