import { useNavigation, useRoute } from '@react-navigation/native';
import { AttendanceCreateInput } from 'core';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Platform,
  SafeAreaView,
  ScrollView,
  TouchableWithoutFeedback,
} from 'react-native';
import { useSelector } from 'react-redux';
import Toast from 'react-native-toast-message';
import { useTheme } from 'styled-components/native';

import PageMain from '@components/Page';
import {
  DateRangePicker,
  DropDownPicker,
  InputField,
  Loading,
} from '@components';
import { BasicButton } from '@components/Button';
import {
  LEAVE_TYPES,
  LEAVE_ID,
  COMPENSATION_EVENT_TYPE_ID,
  LEAVE_EVENT_TYPE_ID,
  LEAVE_STATUS,
} from '@constants';
import ROUTES from '@navigation/routes';
import attendancesApi, {
  useCreateEmployeeAttendanceMutation,
  useGetEmployeeOvertimesQuery,
  useUpdateEmployeeAttendanceMutation,
} from '@redux/apis/attendances';
import { RootState } from '@redux/store';
import { formatDate, parseDate } from '@utils/dateTime';
import { useAppSelector } from '@redux/hook';
import { EmployeeOvertime, User } from '@types';

import {
  ButtonContainer,
  Container,
  LeaveContainer,
  MessageBox,
  MessageText,
  RequestLeaveContainer,
  Title,
  WarningIcon,
} from './index.style';
import { useLazyGetEventByIdQuery } from '@redux/apis/events';
import { useLazyGetProfileQuery } from '@redux/profile/api';
import useLeaveTypes from '@hook/useLeaveTypes';
import { useGetLeaveRulesQuery } from '@redux/apis/leaveRules';
import i18n from '@config/i18n';
import { getLeaveRule } from '@utils/attendance';

const CreateAttendanceScreen = () => {
  const theme = useTheme();
  const navigation = useNavigation<any>();
  const route = useRoute<any>();
  const { t } = useTranslation();
  const styledTheme = useTheme();
  const user: User | null = useAppSelector(state => state.auth.user);
  const leaveTypes = useLeaveTypes();
  const { data: leaveRules } = useGetLeaveRulesQuery();

  const { data, refetch } = useGetEmployeeOvertimesQuery({
    start: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    end: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
  });
  const [openLeaveType, setOpenLeaveType] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [openOvertimeType, setOpenOvertimeType] = useState(false);
  const [state, setState] = useState<AttendanceCreateInput>({
    id: null,
    description: '',
    start: format(new Date(), 'yyyy-MM-dd'),
    end: format(new Date(), 'yyyy-MM-dd'),
    typeId: LEAVE_EVENT_TYPE_ID,
    leaveTypeId: null,
    overtimeId: null,
    status: 0,
    employeeId: undefined,
    rejectionReason: undefined,
  });
  const isUpdate = route.params?.type === 'edit';
  const isPendingLeave = route.params?.isPendingLeave;
  const activeMonth = route.params?.activeMonth;
  const attendanceId = route.params?.attendanceId;

  const [createLeave, { isLoading }] = useCreateEmployeeAttendanceMutation();
  const [updateLeave, { isLoading: isUpdateLoading }] =
    useUpdateEmployeeAttendanceMutation();
  const [
    getPendingApprovalTrigger,
    { data: pendingApprovalData, isFetching: isFetchingPendingApproval },
  ] = useLazyGetEventByIdQuery();

  const [
    getEmployeeProfile,
    { data: employeeProfile, isFetching: isFetchingEmployeeProfile },
  ] = useLazyGetProfileQuery();

  const rootState = useSelector((state: RootState) => state);

  const getNoticePeriod = React.useMemo(() => {
    return () => {
      const end = new Date(state.end);
      const start = new Date(state.start);
      const today = new Date();

      const leaveRule = getLeaveRule({
        start,
        end,
        leaveRules,
        leaveTypeId: state.leaveTypeId,
        requestDate: today,
      });

      return leaveRule
        ? i18n.t(`app.pending-approval.notice-period.message`, {
            noticePeriod: leaveRule.noticePeriod,
            daysType: leaveRule.daysType,
          })
        : null;
    };
  }, [state.end, state.start, state.leaveTypeId]);

  const onSubmit = async () => {
    const leaveData = {
      ...state,
      leaveTypeId:
        state.typeId === COMPENSATION_EVENT_TYPE_ID ? null : state.leaveTypeId,
    };

    if (isUpdate) {
      await updateLeave(leaveData)
        .unwrap()
        .then(() => {
          Toast.show({
            type: 'success',
            text1: t('app.create-attendance.success-message.update') || '',
          });

          clearState();

          if (isPendingLeave) {
            navigation.navigate(ROUTES.APP_PENDING_LEAVE);
          } else {
            navigation.navigate(ROUTES.APP_MY_ATTENDANCE);
          }
        })
        .catch(error => {
          if (error && error.data) {
            Toast.show({
              type: 'error',
              text1: error.data,
            });
          } else {
            Toast.show({
              type: 'error',
              text1: t('app.common.error-message') || '',
            });
          }
        });
    } else {
      await createLeave({
        ...leaveData,
        employeeId: user?.employeeId,
      })
        .unwrap()
        .then(() => {
          Toast.show({
            type: 'success',
            text1: t('app.create-attendance.success-message.create') || '',
          });

          clearState();

          navigation.navigate(ROUTES.APP_MY_ATTENDANCE);
        })
        .catch(error => {
          if (error && error.data) {
            Toast.show({
              type: 'error',
              text1:
                error.data ||
                error.data?.message ||
                t('app.common.error-message'),
            });
          } else {
            Toast.show({
              type: 'error',
              text1: t('app.common.error-message') || '',
            });
          }
        });
    }
  };

  const clearState = () => {
    const defaultAttendance = {
      id: null,
      description: '',
      start: format(new Date(), 'yyyy-MM-dd'),
      end: format(new Date(), 'yyyy-MM-dd'),
      leaveTypeId: null,
      typeId: LEAVE_EVENT_TYPE_ID,
      overtimeId: null,
      employeeId: user?.employeeId,
      rejectionReason: undefined,
    };

    if (route.params && 'startDate' in route.params) {
      const startDate: any = route.params.startDate;

      defaultAttendance.start = format(new Date(startDate), 'yyyy-MM-dd');
      defaultAttendance.end = format(new Date(startDate), 'yyyy-MM-dd');
    }

    setState(defaultAttendance);
  };

  const getCompensationLeaveDate = () => {
    const overtimeData = data?.map((value: EmployeeOvertime) => ({
      id: value.id,
      title: `${value.start} to ${value.end}`,
      typeId: COMPENSATION_EVENT_TYPE_ID,
    }));

    return overtimeData;
  };

  const getAttendance = () => {
    const activeMonth = route.params?.activeMonth;
    const attendanceId = route.params?.attendanceId;

    if (activeMonth && attendanceId) {
      const monthStart = formatDate(
        startOfMonth(parseDate(activeMonth)),
        'yyyy-MM-dd',
      );
      const monthEnd = formatDate(
        endOfMonth(parseDate(activeMonth)),
        'yyyy-MM-dd',
      );

      let attendance;

      if (!isPendingLeave) {
        const { data } = attendancesApi.endpoints.getMyAttendances.select({
          start: monthStart,
          end: monthEnd,
        })(rootState);

        attendance = data;
      }

      return attendance?.find(value => value.id === parseInt(attendanceId));
    }

    return undefined;
  };

  const noticePeriodMessage = getNoticePeriod();

  const RequestLeaveLayout = () => {
    const isOnProbation = employeeProfile?.isOnProbation;
    const FilteredLeaveTypes = isUpdate
      ? leaveTypes
      : leaveTypes.filter(leave => {
          const leaveType = LEAVE_TYPES.find(type => leave.id === type.id);

          return (
            leaveType &&
            (!isOnProbation || leave.id !== LEAVE_ID.ANNUAL_LEAVE) &&
            (isOnProbation || leave.id !== LEAVE_ID.LWP)
          );
        });

    return (
      <>
        <DropDownPicker
          value={state.leaveTypeId}
          items={FilteredLeaveTypes.map(leaveTypes => ({
            ...leaveTypes,
            disabled:
              data?.length === 0 && leaveTypes.id === COMPENSATION_EVENT_TYPE_ID
                ? true
                : false,
          }))}
          onItemSelect={(item: any) =>
            setState({
              ...state,
              leaveTypeId: item.id,
              typeId: item.typeId,
              overtimeId: null,
            })
          }
          placeholder="Select leave type"
          labelText={t('app.create-attendance.label.attendance-type')}
          showLabel
          schema={{
            label: 'title',
            value: 'id',
          }}
          openDropdown={openLeaveType}
          setOpenDropdown={setOpenLeaveType}
          containerStyle={{
            zIndex: openLeaveType ? 1000 : 0,
          }}
          disabled={isUpdate}
        />
        {state.typeId === COMPENSATION_EVENT_TYPE_ID && (
          <DropDownPicker
            value={state.overtimeId}
            items={getCompensationLeaveDate() || []}
            onItemSelect={(item: any) =>
              setState({
                ...state,
                leaveTypeId: item.typeId,
                typeId: item.typeId,
                overtimeId: item.id,
              })
            }
            labelText={t('app.create-attendance.label.overtime-date')}
            showLabel
            schema={{
              label: 'title',
              value: 'id',
            }}
            openDropdown={openOvertimeType}
            setOpenDropdown={setOpenOvertimeType}
            containerStyle={{
              zIndex: openOvertimeType ? 1000 : 0,
            }}
            placeholder={
              isUpdate && state.overtimeId
                ? String(state.overtimeId)
                : t('app.create-attendance.placeholder.overtime-date') || ''
            }
          />
        )}
        <DateRangePicker
          showLabel
          labelText={t('app.create-attendance.label.leave-date')}
          onSuccess={(startDate: string, endDate: string) =>
            setState({
              ...state,
              start: format(new Date(startDate), 'yyyy-MM-dd'),
              end: format(new Date(endDate), 'yyyy-MM-dd'),
            })
          }
          value={`${formatDate(
            new Date(state.start),
            'dd MMM yyyy',
          )} - ${formatDate(new Date(state.end), 'dd MMM yyyy')}`}
          fromDate={format(new Date(state.start), 'yyyy-MM-dd')}
          toDate={format(new Date(state.end), 'yyyy-MM-dd')}
        />
        <InputField
          onChange={(value: string) =>
            setState({ ...state, description: value })
          }
          labelText={t('app.create-attendance.label.reason')}
          inputStyle={{
            textAlignVertical: 'top',
            height: 80,
          }}
          showLabel
          multiline
          value={state.description || ''}
        />
        {isPendingLeave &&
          user?.isManager &&
          state.employeeId !== user?.employeeId && (
            <>
              <DropDownPicker
                value={state.status || 0}
                items={LEAVE_STATUS}
                onItemSelect={(item: any) =>
                  setState({
                    ...state,
                    status: item.id,
                  })
                }
                labelText={'Status'}
                showLabel
                schema={{
                  label: 'title',
                  value: 'id',
                }}
                openDropdown={openStatus}
                setOpenDropdown={setOpenStatus}
                containerStyle={{
                  zIndex: openStatus ? 1000 : 0,
                }}
              />
              {state.status === 2 && (
                <InputField
                  onChange={(value: string) =>
                    setState({ ...state, rejectionReason: value })
                  }
                  labelText={'Rejection reason'}
                  inputStyle={{
                    textAlignVertical: 'top',
                    height: 80,
                  }}
                  showLabel
                  multiline
                  value={state.rejectionReason || ''}
                />
              )}
            </>
          )}
        {noticePeriodMessage && (
          <MessageBox>
            <WarningIcon />
            <MessageText type={'warning'}>{noticePeriodMessage}</MessageText>
          </MessageBox>
        )}
        <ButtonContainer>
          {Platform.OS === 'web' && (
            <BasicButton
              label={t('app.create-attendance.label.cancel')}
              onPress={() => {
                clearState();
                if (isPendingLeave) {
                  navigation.navigate(ROUTES.APP_PENDING_LEAVE);
                } else {
                  navigation.navigate(ROUTES.APP_MY_ATTENDANCE);
                }
              }}
              buttonStyle={{
                marginTop: 20,
                width: 120,
                marginRight: 20,
                backgroundColor: theme.color.lightBlack,
              }}
            />
          )}
          <BasicButton
            label={
              isUpdate
                ? t('app.create-attendance.label.update')
                : t('app.create-attendance.label.submit')
            }
            onPress={() => onSubmit()}
            buttonStyle={{
              marginTop: 20,
              width: Platform.OS === 'web' ? 120 : '100%',
            }}
            loading={isLoading || isUpdateLoading}
            disabled={
              (state.typeId === 8 && state.overtimeId === null) ||
              (state.leaveTypeId === null && state.typeId === 4)
            }
          />
        </ButtonContainer>
      </>
    );
  };

  useEffect(() => {
    if (openOvertimeType) {
      setOpenOvertimeType(true);
      setOpenLeaveType(false);
      setOpenStatus(false);
    }

    if (openLeaveType) {
      setOpenOvertimeType(false);
      setOpenLeaveType(true);
      setOpenStatus(false);
    }
  }, [openLeaveType, openOvertimeType]);

  useEffect(() => {
    if (openStatus) {
      setOpenOvertimeType(false);
      setOpenLeaveType(false);
      setOpenStatus(true);
    }
  }, [openStatus]);

  useEffect(() => {
    refetch();
    getEmployeeProfile({});
  }, [navigation]);

  useEffect(() => {
    if (pendingApprovalData) {
      setState({
        description: pendingApprovalData.description,
        end: pendingApprovalData.end,
        id: pendingApprovalData.id,
        start: pendingApprovalData.start,
        typeId: pendingApprovalData.typeId,
        overtimeId: pendingApprovalData.overtimeId,
        leaveTypeId: pendingApprovalData.overtimeId
          ? pendingApprovalData.typeId
          : pendingApprovalData.leaveTypeId || null,
        status: pendingApprovalData.status,
        employeeId: pendingApprovalData.employeeId,
        rejectionReason: pendingApprovalData.rejectionReason,
      });
    }
  }, [pendingApprovalData]);

  useEffect(() => {
    if (isPendingLeave) {
      getPendingApprovalTrigger({
        id: attendanceId,
        start: activeMonth,
        end: activeMonth,
      });
    } else {
      const employeeAttendance = getAttendance();

      if (employeeAttendance) {
        setState({
          description: employeeAttendance.description,
          end: employeeAttendance.end,
          id: employeeAttendance.id,
          start: employeeAttendance.start,
          typeId: employeeAttendance.typeId,
          overtimeId: employeeAttendance.overtimeId,
          leaveTypeId: employeeAttendance.overtimeId
            ? employeeAttendance.typeId
            : employeeAttendance.leaveTypeId || null,
          status: employeeAttendance.status,
          employeeId: employeeAttendance.employeeId,
          rejectionReason: employeeAttendance.rejectionReason,
        });
      } else {
        clearState();
      }
    }
  }, []);

  return Platform.OS === 'web' ? (
    <PageMain loading={isFetchingPendingApproval && isFetchingEmployeeProfile}>
      <TouchableWithoutFeedback
        onPress={() => {
          setOpenOvertimeType(false);
          setOpenLeaveType(false);
          setOpenStatus(false);
        }}>
        <RequestLeaveContainer>
          <Title theme={styledTheme}>{t('app.screens.request-leave')}</Title>
          <LeaveContainer
            theme={styledTheme}
            style={{
              shadowOffset: { width: -2, height: 4 },
              shadowColor: theme.color.lightBlack,
              shadowOpacity: 0.1,
              shadowRadius: 5,
              borderRadius: 10,
              minWidth:
                styledTheme.size.isLarge || styledTheme.size.isMedium
                  ? '60%'
                  : '30%',
              maxWidth: 600,
            }}>
            {RequestLeaveLayout()}
          </LeaveContainer>
        </RequestLeaveContainer>
      </TouchableWithoutFeedback>
    </PageMain>
  ) : isFetchingPendingApproval && isFetchingEmployeeProfile ? (
    <Loading />
  ) : (
    <TouchableWithoutFeedback
      onPress={() => {
        setOpenOvertimeType(false);
        setOpenLeaveType(false);
        setOpenStatus(false);
      }}>
      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView
          automaticallyAdjustKeyboardInsets={true}
          showsVerticalScrollIndicator={false}
          keyboardDismissMode={'on-drag'}>
          <Container>{RequestLeaveLayout()}</Container>
        </ScrollView>
      </SafeAreaView>
    </TouchableWithoutFeedback>
  );
};

export default CreateAttendanceScreen;
