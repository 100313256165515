import { configContext } from '@dzangolab/react-config';
import React, { useContext } from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import { AppConfigOverride } from '@config';
import i18n from '@config/i18n';
import GeneralAttendance from '@screens/GeneralAttendance';
import {
  ProfileScreen,
  Dashboard,
  MyAttendanceScreen,
  CreateAttendanceScreen,
  TimelineScreen,
  PendingEventsScreen,
  LoansScreen,
  LoanDetailsScreen,
  RequestLoanScreen,
  PayslipsScreen,
  ContractsScreen,
  PrivacyPolicyScreen,
  HolidaysScreen,
  LeaveSummaryScreen,
  OtherDocsScreen,
} from '@screens';

import ROUTES from './routes';

const Stack = createNativeStackNavigator();

const StackNavigator = () => {
  const config = useContext(configContext) as AppConfigOverride;

  return (
    <Stack.Navigator>
      <Stack.Screen
        name={ROUTES.APP_DASHBOARD}
        component={Dashboard}
        options={{
          title: i18n.t('app.screens.dashboard') ?? ROUTES.APP_DASHBOARD,
        }}
      />
      <Stack.Screen
        name={ROUTES.APP_PROFILE}
        component={ProfileScreen}
        options={{ title: i18n.t('app.screens.profile') ?? ROUTES.APP_PROFILE }}
      />
      <Stack.Screen
        name={ROUTES.APP_MY_ATTENDANCE}
        component={MyAttendanceScreen}
        options={{
          title:
            i18n.t('app.screens.my-attendance') ?? ROUTES.APP_MY_ATTENDANCE,
        }}
      />
      <Stack.Screen
        name={ROUTES.APP_GENERAL_ATTENDANCE}
        component={GeneralAttendance}
        options={{
          title:
            i18n.t('app.screens.general-attendance') ??
            ROUTES.APP_GENERAL_ATTENDANCE,
        }}
      />
      <Stack.Screen
        component={CreateAttendanceScreen}
        name={ROUTES.APP_CREATE_ATTENDANCE}
        options={{
          title:
            i18n.t('app.screens.request-leave') ?? ROUTES.APP_MY_ATTENDANCE,
        }}
      />
      <Stack.Screen
        component={TimelineScreen}
        name={ROUTES.APP_TIMELINE}
        options={{
          title: i18n.t('app.screens.timeline') ?? ROUTES.APP_TIMELINE,
        }}
      />
      <Stack.Screen
        component={PendingEventsScreen}
        name={ROUTES.APP_PENDING_LEAVE}
        options={{
          title:
            i18n.t('app.screens.pending-approval') ?? ROUTES.APP_PENDING_LEAVE,
        }}
      />
      {config.features.loan && (
        <Stack.Screen
          component={LoansScreen}
          name={ROUTES.APP_LOANS}
          options={{
            title: i18n.t('app.screens.loans') ?? ROUTES.APP_LOANS,
          }}
        />
      )}
      {config.features.loan && (
        <Stack.Screen
          component={LoanDetailsScreen}
          name={ROUTES.APP_LOAN_DETAILS}
          options={{
            title:
              i18n.t('app.screens.loan-details') ?? ROUTES.APP_LOAN_DETAILS,
          }}
        />
      )}
      {config.features.requestLoan && (
        <Stack.Screen
          component={RequestLoanScreen}
          name={ROUTES.APP_REQUEST_LOAN}
          options={{
            title:
              i18n.t('app.screens.request-loan') ?? ROUTES.APP_REQUEST_LOAN,
          }}
        />
      )}
      <Stack.Screen
        component={PayslipsScreen}
        name={ROUTES.APP_PAYSLIPS}
        options={{
          title: i18n.t('app.screens.payslips') ?? ROUTES.APP_PAYSLIPS,
        }}
      />
      {config.features.contracts && (
        <Stack.Screen
          component={ContractsScreen}
          name={ROUTES.APP_CONTRACTS}
          options={{
            title: i18n.t('app.screens.contracts') ?? ROUTES.APP_CONTRACTS,
          }}
        />
      )}
      <Stack.Screen
        component={PrivacyPolicyScreen}
        name={ROUTES.APP_PRIVACY_POLICY}
        options={{
          title: 'Privacy policy' ?? ROUTES.APP_PRIVACY_POLICY,
          headerShown: false,
        }}
      />
      <Stack.Screen
        component={HolidaysScreen}
        name={ROUTES.APP_HOLIDAYS}
        options={{
          title: i18n.t('app.screens.holidays') || ROUTES.APP_HOLIDAYS,
        }}
      />
      <Stack.Screen
        component={OtherDocsScreen}
        name={ROUTES.APP_OTHER_DOCUMENTS}
        options={{
          title: i18n.t('app.screens.other-docs') || ROUTES.APP_OTHER_DOCUMENTS,
        }}
      />
      <Stack.Screen
        component={LeaveSummaryScreen}
        name={ROUTES.APP_LEAVE_SUMMARY}
        options={{
          title:
            i18n.t('app.screens.leave-summary') || ROUTES.APP_LEAVE_SUMMARY,
        }}
      />
    </Stack.Navigator>
  );
};

const AppNavigator = () => {
  return <StackNavigator />;
};

export default AppNavigator;
