import { Loan, LoanTransaction } from 'core';

import i18n from '@config/i18n';

import { formatDate, formatDateRange, parseDate } from './dateTime';
import { differenceInMonths, parse } from 'date-fns';
import { MAXIMUM_LOAN_AMOUNT } from '@constants';

const getLoanStartDate = (loan: Loan) => {
  const startDate =
    loan.status === 'GRANTED'
      ? loan.grantOn
      : getExpectedStartDate(loan.transactions);

  if (startDate) {
    return formatDate(parseDate(startDate), 'dd MMM yyyy');
  }

  return null;
};

const getLoanEndDate = (loan: Loan) => {
  const endDate = getLastTransactionDate(loan.transactions);

  if (endDate) {
    return formatDate(parseDate(endDate), 'dd MMM yyyy');
  }

  return null;
};

const getExpectedStartDate = (transactions: LoanTransaction[]) => {
  const dates: string[] = [];

  if (transactions.length) {
    transactions.forEach(transaction => {
      dates.push(transaction.date);
    });

    var minDate = dates.reduce(function (prevDate, nextDate) {
      return prevDate < nextDate ? prevDate : nextDate;
    });

    return minDate;
  }

  return null;
};

const getLastTransactionDate = (transactions: LoanTransaction[]) => {
  const dates: string[] = [];

  if (transactions.length) {
    transactions.forEach(element => {
      dates.push(element.date);
    });

    if (dates.length) {
      var maxDate = dates.reduce(function (prevDate, nextDate) {
        return prevDate > nextDate ? prevDate : nextDate;
      });

      return maxDate;
    }
  }

  return null;
};

const getLoanDuration = (loan: Loan) => {
  const start =
    loan.status === 'GRANTED'
      ? loan.grantOn
      : getExpectedStartDate(loan.transactions);
  const end = getLastTransactionDate(loan.transactions);

  if (start && !end) {
    const startDate = parseDate(start);

    return `Start: ${formatDate(startDate, 'dd MMM yyyy')}`;
  }

  if (end && !start) {
    const endDate = parseDate(end);

    return `End: ${formatDate(endDate, 'dd MMM yyyy')}`;
  }

  if (start && end) {
    const startDate = parseDate(start);
    const endDate = parseDate(end);

    return formatDateRange(startDate, endDate);
  }
};

const getLoanDurationInMonths = (loan: Loan) => {
  let months = 0;
  const firstInstallmentDate = parseDate(loan.transactions[1].date);
  const lastInstalmentDate = getLastTransactionDate(loan.transactions);

  if (firstInstallmentDate && lastInstalmentDate) {
    const endDate = parseDate(lastInstalmentDate);

    months = (endDate.getFullYear() - firstInstallmentDate.getFullYear()) * 12;
    months += endDate.getMonth() - firstInstallmentDate.getMonth() + 1;
  }

  return months;
};

const getLoanTitle = (loan: Loan) => {
  switch (loan.type) {
    case 'LOAN':
      return i18n.t('app.loan.type.loan');
    case 'SALARY_ADVANCE':
      return i18n.t('app.loan.type.salary-advance');
    case 'INVERTER_LOAN':
      return i18n.t('app.loan.type.inverter-loan');
    default:
      return 'Loan';
  }
};

const getLoanStatus = (status: Loan['status']) => {
  switch (status) {
    case 'ACCEPTED':
      return i18n.t('app.loan.status.accepted');
    case 'APPROVED':
      return i18n.t('app.loan.status.approved');
    case 'GRANTED':
      return i18n.t('app.loan.status.granted');
    case 'PAID':
      return i18n.t('app.loan.status.paid');
    case 'PROPOSED':
      return i18n.t('app.loan.status.proposed');
    default:
      return '~';
  }
};

const getMaxSalaryAdvanceAmount = (salary: number) => {
  return Math.floor(salary * 0.6);
};

const getMaxLoanAmount = (
  salary: number,
  joinedAt?: string | null,
  isManager?: boolean,
) => {
  const getBaseLoanAmount = () => {
    let seniorityInMonths = 0;

    if (isManager) {
      return MAXIMUM_LOAN_AMOUNT;
    }

    if (joinedAt) {
      seniorityInMonths = differenceInMonths(new Date(), parseDate(joinedAt));
    }

    if (seniorityInMonths >= 36) {
      return salary * 2;
    } else if (seniorityInMonths < 36 && seniorityInMonths >= 12) {
      return salary * 1.5;
    } else if (seniorityInMonths < 12 && seniorityInMonths >= 6) {
      return salary;
    } else {
      return salary * 0.5;
    }
  };

  const baseAmount = getBaseLoanAmount();

  if (baseAmount > MAXIMUM_LOAN_AMOUNT) {
    return MAXIMUM_LOAN_AMOUNT;
  } else {
    return baseAmount;
  }
};

export {
  getExpectedStartDate,
  getLastTransactionDate,
  getLoanDuration,
  getLoanDurationInMonths,
  getLoanEndDate,
  getLoanStartDate,
  getLoanTitle,
  getLoanStatus,
  getMaxLoanAmount,
  getMaxSalaryAdvanceAmount,
};
