import { AppConfig, AppFeatures, parse } from '@dzangolab/react-config';
import { Platform } from 'react-native';

import {
  REACT_APP_BASE_URL,
  REACT_APP_DEEP_LINK_URL,
} from 'react-native-dotenv';

export interface AppConfigOverride extends AppConfig {
  features: {
    showVersion: boolean;
    showLeaveRequest: boolean;
    loan: boolean;
    requestLoan: boolean;
    documents: boolean;
    contracts: boolean;
    privacyPolicy: boolean;
    showWFO: boolean;
  };
  deepLinkURL: string;
  privacyPolicyUrl: string;
}

const config: AppConfigOverride = {
  apiBaseUrl:
    Platform.OS === 'web' ? process.env.REACT_APP_BASE_URL : REACT_APP_BASE_URL,
  appPort: (Platform.OS === 'web' &&
    parse(process.env.PORT, '20141')) as string,
  appTitle: (Platform.OS === 'web' && process.env.REACT_APP_TITLE) as string,
  appVersion: (Platform.OS === 'web' &&
    `${process.env.REACT_APP_VERSION}+${process.env.REACT_APP_BUILD}`) as string,
  copyright: {
    holder: '',
    url: '',
  },
  features: {
    contracts: process.env.REACT_APP_FEATURE_CONTRACTS_ENABLED === 'true',
    documents: process.env.REACT_APP_FEATURE_DOCUMENTS_ENABLED === 'true',
    loan: process.env.REACT_APP_FEATURE_LOAN_ENABLED === 'true',
    requestLoan: process.env.REACT_APP_FEATURE_LOAN_REQUEST_ENABLED === 'true',
    showVersion: process.env.REACT_APP_FEATURE_SHOW_VERSION_ENABLED === 'true',
    showLeaveRequest:
      process.env.REACT_APP_FEATURE_SHOW_LEAVE_REQUEST_ENABLED === 'true',
    privacyPolicy: process.env.REACT_APP_FEATURE_PRIVACY_POLICY === 'true',
    showWFO: process.env.REACT_APP_FEATURE_SHOW_WFO_ENABLED === 'true',
  },
  websiteDomain: '',
  deepLinkURL:
    Platform.OS === 'web'
      ? process.env.REACT_APP_DEEP_LINK_URL
      : REACT_APP_DEEP_LINK_URL,
  privacyPolicyUrl: process.env.REACT_APP_PRIVACY_POLICY_URL as string,
};

export default config;
