import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Animated, Platform, TouchableOpacity } from 'react-native';
import { useTheme } from 'styled-components/native';
import { configContext } from '@dzangolab/react-config';
import { useNavigation } from '@react-navigation/native';

import AppVersion from '@components/AppVersion';
import { AppConfigOverride } from '@config';
import ROUTES from '@navigation/routes';
import { useAppDispatch, useAppSelector } from '@redux/hook';
import { updateAppearanceSwitcherVisibility } from '@redux/profile/slice';
import { ThemeContext } from '@theme';
import { ThemeType } from '@types';
import { getAppVersion } from '@utils';

import {
  AvatarContainer,
  AvatarContent,
  Container,
  MenuContainer,
  MenuItem,
  MenuText,
  UserDetails,
  UserEmail,
  UserName,
  BottomContainer,
  ThemeSwitch,
  LightIcon,
  ThemeName,
  ThemeSwitchWrapper,
  NestedMenuItem,
  DrawerBody,
  DrawerFooter,
  LinkText,
  MenuLink,
  BottomMenuContainer,
} from './index.style';

interface IProps {
  closeDrawer?: () => void;
  signOutComponent?: React.ReactNode;
}

const AppDrawer: React.FC<IProps> = props => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { navigate } = useNavigation<any>();
  const { t } = useTranslation();
  const user = useAppSelector(state => state.auth.user);
  const { isDefaultTheme, theme: appTheme } = useContext(ThemeContext);
  const config = useContext(configContext) as AppConfigOverride;

  const { closeDrawer } = props;

  const getName = () => {
    if (user?.middleName) {
      return `${user?.givenName} ${user?.middleName} ${user?.surname}`;
    }

    return `${user?.givenName} ${user?.surname}`;
  };

  const openAppearanceModal = () => {
    dispatch(updateAppearanceSwitcherVisibility(true));
  };

  const getThemeName = () => {
    if (isDefaultTheme) return t('app.themes.default');

    if (appTheme === ThemeType.DARK) return t('app.themes.dark');

    if (appTheme === ThemeType.LIGHT) return t('app.themes.light');
  };

  return (
    <Container
      contentContainerStyle={{
        justifyContent: 'space-between',
        flexGrow: 1,
      }}>
      <DrawerBody>
        <UserDetails>
          <AvatarContainer>
            <AvatarContent>{user?.email.charAt(0)}</AvatarContent>
          </AvatarContainer>
          {user?.givenName ? (
            <UserName>{getName()}</UserName>
          ) : (
            <UserName>{user?.email}</UserName>
          )}
          <UserEmail>{user?.email}</UserEmail>
        </UserDetails>
        <MenuContainer>
          <MenuItem
            onPress={() => {
              if (closeDrawer) {
                closeDrawer();
              }

              if (Platform.OS === 'web') navigate(ROUTES.APP_DASHBOARD);
              else
                navigate(ROUTES.APP_DRAWER_DEFAULT, {
                  screen: ROUTES.APP_DASHBOARD,
                });
            }}>
            <MenuText>{t('app.screens.dashboard')}</MenuText>
          </MenuItem>
          <MenuItem
            onPress={() => {
              navigate(ROUTES.APP_MY_ATTENDANCE);
              if (closeDrawer) {
                closeDrawer();
              }
            }}>
            <MenuText>{t('app.screens.my-attendance')}</MenuText>
          </MenuItem>
          <MenuItem
            onPress={() => {
              navigate(ROUTES.APP_GENERAL_ATTENDANCE);
              if (closeDrawer) {
                closeDrawer();
              }
            }}>
            <MenuText>{t('app.screens.general-attendance')}</MenuText>
          </MenuItem>
          <MenuItem
            onPress={() => {
              navigate(ROUTES.APP_LEAVE_SUMMARY);
              if (closeDrawer) {
                closeDrawer();
              }
            }}>
            <MenuText>{t('app.screens.leave-summary')}</MenuText>
          </MenuItem>
          <MenuItem
            onPress={() => {
              navigate(ROUTES.APP_TIMELINE);
              if (closeDrawer) {
                closeDrawer();
              }
            }}>
            <MenuText>{t('app.screens.timeline')}</MenuText>
          </MenuItem>
          {config.features.loan && (
            <MenuItem
              onPress={() => {
                navigate(ROUTES.APP_LOANS);
                if (closeDrawer) {
                  closeDrawer();
                }
              }}>
              <MenuText>{t('app.screens.loans')}</MenuText>
            </MenuItem>
          )}
          <MenuItem
            onPress={() => {
              navigate(ROUTES.APP_HOLIDAYS);
              if (closeDrawer) {
                closeDrawer();
              }
            }}>
            <MenuText>{t('app.screens.holidays')}</MenuText>
          </MenuItem>
          {config.features.documents && (
            <NestedMenuItem>
              <TouchableOpacity disabled>
                <MenuText>{t('app.screens.documents')}</MenuText>
              </TouchableOpacity>
              <Animated.View
                style={{
                  marginLeft: 10,
                }}>
                <MenuItem
                  onPress={() => {
                    navigate(ROUTES.APP_PAYSLIPS);
                    if (closeDrawer) {
                      closeDrawer();
                    }
                  }}
                  style={{
                    borderBottomWidth: 0,
                    paddingBottom: 0,
                  }}>
                  <MenuText
                    style={{
                      padding: 5,
                    }}>
                    {t('app.screens.payslips')}
                  </MenuText>
                </MenuItem>
                <MenuItem
                  onPress={() => {
                    navigate(ROUTES.APP_OTHER_DOCUMENTS);
                    if (closeDrawer) {
                      closeDrawer();
                    }
                  }}
                  style={{
                    borderBottomWidth: 0,
                    paddingBottom: 0,
                  }}>
                  <MenuText
                    style={{
                      padding: 5,
                    }}>
                    {t('app.screens.other-docs')}
                  </MenuText>
                </MenuItem>
                {config.features.contracts && (
                  <MenuItem
                    onPress={() => {
                      navigate(ROUTES.APP_CONTRACTS);
                      if (closeDrawer) {
                        closeDrawer();
                      }
                    }}
                    style={{
                      borderBottomWidth: 0,
                    }}>
                    <MenuText>{t('app.screens.contracts')}</MenuText>
                  </MenuItem>
                )}
              </Animated.View>
            </NestedMenuItem>
          )}
          <MenuItem
            onPress={() => {
              navigate(ROUTES.APP_PROFILE);
              if (closeDrawer) {
                closeDrawer();
              }
            }}>
            <MenuText>{t('app.screens.profile')}</MenuText>
          </MenuItem>
          <ThemeSwitchWrapper>
            <ThemeSwitch onPress={openAppearanceModal}>
              <LightIcon />
              <ThemeName>{getThemeName()}</ThemeName>
            </ThemeSwitch>
          </ThemeSwitchWrapper>
        </MenuContainer>
      </DrawerBody>
      <DrawerFooter>
        {props.signOutComponent && (
          <BottomContainer>
            {props.signOutComponent}
            <AppVersion
              title={t('app.common.app-version') || ''}
              version={getAppVersion()}
            />
          </BottomContainer>
        )}
        {config.features.privacyPolicy && Platform.OS !== 'web' && (
          <BottomMenuContainer>
            <MenuLink
              onPress={() => {
                navigate(ROUTES.APP_PRIVACY_POLICY);
                if (closeDrawer) {
                  closeDrawer();
                }
              }}>
              <LinkText>{t('app.screens.privacy-policy')}</LinkText>
            </MenuLink>
            <MenuLink disabled>
              <LinkText
                style={{
                  color: theme.color.grey,
                }}>
                Terms of Use
              </LinkText>
            </MenuLink>
          </BottomMenuContainer>
        )}
      </DrawerFooter>
    </Container>
  );
};

export default AppDrawer;
