import { useNavigation, useRoute } from '@react-navigation/native';
import { Loan } from 'core';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, SafeAreaView, ScrollView, Modal } from 'react-native';
import { Checkbox, DataTable } from 'react-native-paper';
import { useTheme } from 'styled-components/native';
import Toast from 'react-native-toast-message';

import { ModalOverlay, ScreenMain } from '@commons/styles';
import { ConfirmationDialog, DescriptionList } from '@components';
import { formatCurrency } from '@utils/NumberFormat';
import { formatDate, parseDate } from '@utils/dateTime';
import {
  getLoanEndDate,
  getLoanStartDate,
  getLoanStatus,
  getLoanTitle,
} from '@utils/loans';
import PageMain from '@components/Page';
import { IconButton } from '@components/Button';
import ROUTES from '@navigation/routes';
import { useDeleteLoanMutation } from '@redux/loans/api';

import {
  ActionBar,
  CheckboxLabel,
  CheckboxWrapper,
  Container,
  DeleteConfirmation,
  DeleteConfirmationText,
  HeadingAction,
  HeadingBar,
  LoanDetailCard,
  PaymentScheduleWrapper,
  Title,
} from './index.style';

interface IProps {
  loans: Loan[];
}

const LoanDetails: React.FC<IProps> = props => {
  const route = useRoute<any>();
  const { t } = useTranslation();
  const { navigate } = useNavigation<any>();
  const theme = useTheme();

  const { loans } = props;
  const loanId = route.params?.loanId as number | undefined;
  const [loan, setLoan] = useState<Loan>();
  const [showBalance, setShowBalance] = useState(false);
  const [deleteLoan, { isLoading: isDeletingLoan }] = useDeleteLoanMutation();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  let outstandingAmount = 0;

  const onLoanDelete = () => {
    if (loanId && loan) {
      deleteLoan(loanId)
        .unwrap()
        .then(data => {
          setShowDeleteConfirmation(false);
          Toast.show({
            type: 'success',
            text1: t('app.loan.delete-loan.success') || '',
          });
          navigate(ROUTES.APP_LOANS);
        })
        .catch(() => {
          setShowDeleteConfirmation(false);
          Toast.show({
            type: 'error',
            text1: t('app.loan.delete-loan.failed') || '',
          });
        });
    }
  };

  const onLoanEdit = () => {
    if (loanId && loan) {
      navigate(ROUTES.APP_REQUEST_LOAN, { mode: 'edit', loanId });
    }
  };

  useEffect(() => {
    if (loanId) {
      const loan = _.find(loans, loan => {
        return loan.id === loanId;
      });

      if (loan) {
        setLoan(loan);
      } else {
        navigate(ROUTES.APP_LOANS);
      }
    }
  }, [loanId]);

  const getDeleteConfirmationDialogue = () => {
    return (
      <DeleteConfirmation>
        <DeleteConfirmationText>
          {t('app.loan.delete-loan.confirmation.message')}
        </DeleteConfirmationText>
      </DeleteConfirmation>
    );
  };

  const getLoanDetails = () => {
    const loanDetails = (loan: Loan) => {
      const details = [
        {
          label: t('app.loan.currency'),
          value: loan.currency,
        },
        {
          label: t('app.loan.amount'),
          value: formatCurrency(loan.amount, ''),
        },
        {
          label: t('app.loan.outstanding-balance'),
          value: loan.outstandingAmount
            ? formatCurrency(loan.outstandingAmount, '')
            : '~',
        },
        {
          label: t('app.loan.start'),
          value: getLoanStartDate(loan),
        },
        {
          label: t('app.loan.end'),
          value: getLoanEndDate(loan),
        },
        {
          label: t('app.loan.loan-status'),
          value: getLoanStatus(loan.status),
        },
      ];

      if (Platform.OS === 'web') {
        details.unshift({
          label: t('app.loan.loan-type'),
          value: getLoanTitle(loan),
        });
      }

      return details;
    };
    return (
      <ScreenMain>
        <ScrollView>
          {!!loan ? (
            <Container>
              <HeadingBar>
                <Title>{t('app.loan.details')}</Title>
                <HeadingAction>
                  {loan.status === 'PROPOSED' && (
                    <IconButton
                      onPress={onLoanEdit}
                      featherIconName={'edit'}
                      color={theme.color.green}
                    />
                  )}
                  {loan.status === 'PROPOSED' && (
                    <IconButton
                      onPress={() => setShowDeleteConfirmation(true)}
                      featherIconName={'trash'}
                      color={theme.color.red}
                    />
                  )}
                </HeadingAction>
              </HeadingBar>
              <LoanDetailCard>
                <DescriptionList items={loanDetails(loan)} />
              </LoanDetailCard>
              <PaymentScheduleWrapper>
                <ActionBar>
                  <CheckboxWrapper onPress={() => setShowBalance(!showBalance)}>
                    <Checkbox.Android
                      status={showBalance ? 'checked' : 'unchecked'}
                      color={theme.color.primary}
                    />
                    <CheckboxLabel>
                      {t('app.loan.show-outstanding-balance')}
                    </CheckboxLabel>
                  </CheckboxWrapper>
                </ActionBar>
                <DataTable>
                  <DataTable.Header>
                    <DataTable.Title
                      textStyle={{
                        color: theme.color.text,
                        fontFamily: theme.fonts.MontserratRegular,
                        fontSize: 14,
                        fontWeight: '400',
                        textTransform: 'uppercase',
                      }}>
                      {t('app.date')}
                    </DataTable.Title>
                    <DataTable.Title
                      textStyle={{
                        color: theme.color.text,
                        fontFamily: theme.fonts.MontserratRegular,
                        fontSize: 14,
                        fontWeight: '400',
                        textTransform: 'uppercase',
                      }}
                      style={{
                        justifyContent: 'flex-end',
                        maxWidth: 100,
                      }}>
                      {t('app.amount')}
                    </DataTable.Title>
                    {showBalance && (
                      <DataTable.Title
                        textStyle={{
                          color: theme.color.text,
                          fontFamily: theme.fonts.MontserratRegular,
                          fontSize: 14,
                          fontWeight: '400',
                          textTransform: 'uppercase',
                        }}
                        style={{
                          justifyContent: 'flex-end',
                          maxWidth: 100,
                        }}>
                        {t('app.loan.balance')}
                      </DataTable.Title>
                    )}
                  </DataTable.Header>

                  {loan.transactions.map((transaction, index) => {
                    if (index === 0) {
                      outstandingAmount = transaction.amount;
                    } else {
                      outstandingAmount += transaction.amount;
                    }

                    return (
                      <DataTable.Row key={transaction.id}>
                        <DataTable.Cell
                          textStyle={{
                            color: theme.color.text,
                            fontFamily: theme.fonts.MontserratRegular,
                          }}>
                          {formatDate(
                            parseDate(transaction.date),
                            'dd MMM yyyy',
                          )}
                        </DataTable.Cell>
                        <DataTable.Cell
                          textStyle={{
                            color: theme.color.text,
                            fontFamily: theme.fonts.MontserratRegular,
                          }}
                          style={{
                            justifyContent: 'flex-end',
                            maxWidth: 100,
                          }}>
                          {formatCurrency(transaction.amount, '')}
                        </DataTable.Cell>
                        {showBalance && (
                          <DataTable.Cell
                            textStyle={{
                              color: theme.color.text,
                              fontFamily: theme.fonts.MontserratRegular,
                            }}
                            style={{
                              justifyContent: 'flex-end',
                              maxWidth: 100,
                            }}>
                            {formatCurrency(outstandingAmount, '')}
                          </DataTable.Cell>
                        )}
                      </DataTable.Row>
                    );
                  })}
                </DataTable>
              </PaymentScheduleWrapper>
            </Container>
          ) : (
            <></>
          )}
        </ScrollView>
        <Modal
          animationType={'fade'}
          transparent
          visible={showDeleteConfirmation}
          supportedOrientations={['landscape', 'portrait']}>
          <ModalOverlay onPress={() => setShowDeleteConfirmation(false)}>
            <ConfirmationDialog
              title={t('app.loan.delete-loan.confirmation.title')}
              confirmLabel={t('app.loan.delete-loan.confirmation.delete')}
              onConfirm={() => onLoanDelete()}
              onCancel={() => setShowDeleteConfirmation(false)}
              cancelLabel={t('app.loan.delete-loan.confirmation.cancel')}
              color={theme.color.red}
              isConfirmationLoading={isDeletingLoan}>
              {getDeleteConfirmationDialogue()}
            </ConfirmationDialog>
          </ModalOverlay>
        </Modal>
      </ScreenMain>
    );
  };

  if (Platform.OS === 'web') {
    return (
      <PageMain title={t('app.loan.loan-details')}>{getLoanDetails()}</PageMain>
    );
  }

  return <SafeAreaView style={{ flex: 1 }}>{getLoanDetails()}</SafeAreaView>;
};

export default LoanDetails;
