import { configContext } from '@dzangolab/react-config';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { endOfMonth, startOfMonth } from 'date-fns';
import React, { useContext, useEffect } from 'react';
import { Platform, SafeAreaView } from 'react-native';

import PageMain from '@components/Page';
import { FloatingButton } from '@components';
import ROUTES from '@navigation/routes';
import { useLazyGetMyAttendancesQuery } from '@redux/myAttendance/api';
import { useLazyGetHolidaysQuery } from '@redux/apis/holidays';
import { formatDate, parseDate } from '@utils/dateTime';
import { Attendance, Holiday } from '@types';
import { AppConfigOverride } from '@config';

import { AttendanceCalendar } from './components';
import { Container } from './index.style';
import { CustomCalendarMarking } from '../../types/attendance';

interface IProps {
  activeMonthMarkings: CustomCalendarMarking;
  holidayMarkings: CustomCalendarMarking;
  activeMonth: string | null;
  fetchedMonths: string[];
  setAttendance: (month: string, attendance: Attendance[]) => void;
  setHolidays: (holidays: Holiday[]) => void;
  setActiveMonth: (activeMonth: string) => void;
  updateFetchedMonths: (months: string[]) => void;
}

const MyAttendanceScreen: React.FC<IProps> = props => {
  const {
    activeMonth,
    activeMonthMarkings,
    fetchedMonths,
    holidayMarkings,
    setAttendance,
    setHolidays,
    setActiveMonth,
    updateFetchedMonths,
  } = props;
  const navigation = useNavigation<any>();
  const config = useContext(configContext) as AppConfigOverride;
  const isFocused = useIsFocused();
  const [
    attendanceTrigger,
    { data: attendanceData, isFetching: isFetchingAttendance },
  ] = useLazyGetMyAttendancesQuery();
  const [
    holidayTrigger,
    { data: holidaysData, isFetching: isFetchingHolidays },
  ] = useLazyGetHolidaysQuery();

  useEffect(() => {
    if (holidaysData) setHolidays(holidaysData);
  }, [holidaysData]);

  useEffect(() => {
    const monthStart = formatDate(startOfMonth(new Date()), 'yyyy-MM-dd');

    setActiveMonth(monthStart);
    holidayTrigger({});
  }, []);

  useEffect(() => {
    if (attendanceData && activeMonth) {
      setAttendance(activeMonth, attendanceData);
    }
  }, [attendanceData]);

  useEffect(() => {
    if (activeMonth) {
      const monthStart = formatDate(
        startOfMonth(parseDate(activeMonth)),
        'yyyy-MM-dd',
      );
      const monthEnd = formatDate(
        endOfMonth(parseDate(activeMonth)),
        'yyyy-MM-dd',
      );

      fetchAttendance(monthStart, monthEnd);
    }
  }, [activeMonth]);

  useEffect(() => {
    if (activeMonth) {
      onRefresh(activeMonth);
    }
  }, [isFocused]);

  const onMonthChange = (date: Date) => {
    const monthStart = formatDate(startOfMonth(date), 'yyyy-MM-dd');
    setActiveMonth(monthStart);
  };

  const fetchAttendance = (start: string, end: string, refresh?: boolean) => {
    if (!fetchedMonths.includes(start) || refresh) {
      attendanceTrigger({ start, end }, !refresh);

      const months = refresh ? [] : fetchedMonths;

      updateFetchedMonths([...months, start]);
    }
  };

  const onRefresh = (activeMonth: string) => {
    const monthStart = formatDate(
      startOfMonth(parseDate(activeMonth)),
      'yyyy-MM-dd',
    );
    const monthEnd = formatDate(
      endOfMonth(parseDate(activeMonth)),
      'yyyy-MM-dd',
    );

    fetchAttendance(monthStart, monthEnd, true);
  };

  if (Platform.OS === 'web') {
    return (
      <PageMain>
        {activeMonth && (
          <AttendanceCalendar
            attendanceMarkings={activeMonthMarkings}
            holidayMarkings={holidayMarkings}
            onRefresh={onRefresh}
            loading={isFetchingAttendance || isFetchingHolidays}
            onMonthChange={onMonthChange}
            activeMonth={activeMonth}
          />
        )}

        {config.features.showLeaveRequest && (
          <FloatingButton
            onPress={() => navigation.navigate(ROUTES.APP_CREATE_ATTENDANCE)}
          />
        )}
      </PageMain>
    );
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Container
        contentContainerStyle={{ flexGrow: 1 }}
        showsVerticalScrollIndicator={false}>
        {activeMonth && (
          <AttendanceCalendar
            attendanceMarkings={activeMonthMarkings}
            holidayMarkings={holidayMarkings}
            onRefresh={onRefresh}
            loading={isFetchingHolidays || isFetchingAttendance}
            onMonthChange={onMonthChange}
            activeMonth={activeMonth}
          />
        )}

        {config.features.showLeaveRequest && (
          <FloatingButton
            onPress={() => navigation.navigate(ROUTES.APP_CREATE_ATTENDANCE)}
          />
        )}
      </Container>
    </SafeAreaView>
  );
};

export default MyAttendanceScreen;
