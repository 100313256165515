import { Loan } from 'core';
import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';

import { RootState } from '@redux/store';
import { getMyLoans } from '@redux/loans/selectors';
import { setLoans } from '@redux/loans/slice';

import LoansScreen from './index.component';

const mapStateToProps = (state: RootState) => ({
  loans: getMyLoans(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setLoans: (loans: Loan[]) => {
    dispatch(setLoans(loans));
  },
});

const ConnectedLoansScreen = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoansScreen);

export default ConnectedLoansScreen;
