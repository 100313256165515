import { configContext } from '@dzangolab/react-config';
import React, { useContext } from 'react';

import { AppConfigOverride } from '@config';
import ROUTES from '@navigation/routes';
import { Menu } from '@types';

import { MenuItem } from './components';
import { MenuBarContainer } from './index.style';
import i18n from '@config/i18n';

const MenuBar: React.FC = () => {
  const config = useContext(configContext) as AppConfigOverride;
  const menuItems: Menu[] = [
    {
      key: 'Dashboard',
      title: i18n.t(`app.screens.dashboard`),
      route: ROUTES.APP_DASHBOARD,
    },
    {
      key: 'Attendance',
      title: i18n.t(`app.screens.attendance`),
      route: ROUTES.APP_MY_ATTENDANCE,
      children: [
        {
          key: 'MyAttendance',
          title: i18n.t(`app.screens.my-attendance`),
          route: ROUTES.APP_MY_ATTENDANCE,
        },
        {
          key: 'GeneralAttendance',
          title: i18n.t(`app.screens.general-attendance`),
          route: ROUTES.APP_GENERAL_ATTENDANCE,
        },
      ],
    },
    {
      key: 'Timeline',
      title: i18n.t(`app.screens.timeline`),
      route: ROUTES.APP_TIMELINE,
    },
  ];

  if (config.features.loan) {
    menuItems.push({
      key: 'Loans',
      title: i18n.t(`app.screens.loans`),
      route: ROUTES.APP_LOANS,
    });
  }

  if (config.features.documents) {
    menuItems.push({
      key: 'Documents',
      title: i18n.t(`app.screens.documents`),
      route: ROUTES.APP_PAYSLIPS,
      children: [
        {
          key: 'Payslips',
          title: i18n.t(`app.screens.payslips`),
          route: ROUTES.APP_PAYSLIPS,
        },
        {
          key: 'Others',
          title: i18n.t(`app.screens.other-docs`),
          route: ROUTES.APP_OTHER_DOCUMENTS,
        },
      ],
    });
  }

  menuItems.push({
    key: 'leaveSummary',
    title: i18n.t(`app.screens.leave-summary`),
    route: ROUTES.APP_LEAVE_SUMMARY,
  });

  menuItems.push({
    key: 'holidays',
    title: i18n.t(`app.screens.holidays`),
    route: ROUTES.APP_HOLIDAYS,
  });

  return (
    <MenuBarContainer>
      {menuItems.map(menu => (
        <MenuItem menu={menu} key={menu.title} />
      ))}
    </MenuBarContainer>
  );
};

export default MenuBar;
